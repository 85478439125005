.stats-count {
  font-size: 20px;
  font-weight: bold;
}

.DateRangePicker_picker {
  z-index: 99999 !important;
}

.tabtab-container {
  background-color: #fff;
}

.plan-yearly {
  cursor: pointer;
  background-color: #4ca7f8;
  border: 2px solid #4ca7f8;
  margin: 5px;
  position: relative;
}

.plan-yearly > .plan-desc {
  padding: 10px 40px 10px 40px;
  height: 120px;
}

.plan-yearly > .plan-desc > .plan-sale-price {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
}
.plan-yearly > .plan-desc > .plan-price {
  font-size: 22px;
  color: #94d3fb;
  text-decoration: line-through;
  font-weight: bold;
}
.plan-yearly > .plan-cta {
  background-color: #fff;
  width: 100%;
  font-weight: bold;
  color: #4ca7f8;
  border-top: 2px solid #4ca7f8;
  position: absolute;
  bottom: 0;
  left: 0;
}

.payment-msg {
  border: 1px solid #ddd;
  padding: 15px;
  width: 575px;
  margin: 0 auto;
}

.plan-monthly {
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #b2b2b2;
  margin: 5px;
  position: relative;
}

.plan-monthly > .plan-desc {
  padding: 10px 40px 10px 40px;
  height: 120px;
}

.plan-monthly > .plan-desc > .plan-sale-price {
  font-size: 22px;
  font-weight: bold;
  color: #b2b2b2;
}
.plan-monthly > .plan-cta {
  background-color: #fff;
  width: 100%;
  font-weight: bold;
  color: #b2b2b2;
  border-top: 2px solid #b2b2b2;
}

.time-remaining-digits {
  font-size: 32px;
  font-weight: bold;
  margin-right: 5px;
}

.time-remaining-label {
  /* font-size: 32px;
  font-weight: bold; */
  margin-right: 10px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1280px;
  }
}

#button-tooltip > .tooltip-inner {
  background-color: #fff;
  color: #000;
  min-width: 250px;
  max-width: 100%;
  border: 1px solid #062e56;
}

#button-tooltipp > .tooltip-arrow {
  border-top: 5px solid #062e56;
}

.offer-prices {
  border: 1px solid #ddd;
  margin: 5px;
  padding: 2px;
  cursor: pointer;
  text-align: left;
}

.amazon_price_match {
  background-color: #7c4518;
  color: #fff;
}

.my_price_match {
  background-color: #52835c;
  color: #fff;
}

.offer-prices:hover {
  background-color: #fff;
}

.my_price_match:hover {
  background-color: #fff;
  color: #000;
}

.amazon_price_match:hover {
  background-color: #fff;
  color: #000;
}

.editprice-buttons {
  font-size: 12px;
  font-weight: bold;
  padding: 0;
}

.editprice-input {
  width: 72px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  padding: 3px;
  border-radius: 0;
  /* background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc; */
}

.editprice-input::-webkit-outer-spin-button,
.editprice-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.editprice-input[type='number'] {
  -moz-appearance: textfield;
}

.price-current-unmatch {
  padding: 6px 3px 6px 3px;
  border: 1px solid #c0c0c0;
  font-weight: bold;
  color: #cb444a;
  cursor: pointer;
}

.form-group {
  margin-bottom: 1rem;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col {
  padding-left: 5px;
  padding-right: 5px;
}

label {
  margin-left: 0.5rem;
  margin-top: 0.2rem;
}

.price-current-match {
  padding: 6px 3px 6px 3px;
  border: 1px solid #c0c0c0;
  font-weight: bold;
  color: #408558;
  cursor: pointer;
}

.infoboxes {
  min-width: 201px;
  padding: 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  margin: 20px 20px 20px 0px;
  text-align: center;
  border-radius: 2px;
  background: #fff;
}

.home-page-dd-options-right {
  font-size: 12px;
  font-weight: bold;
}

label[for='custom-switch'] {
  padding-top: 2px !important;
  font-size: 13px;
}

.button-active-inventory {
  background-color: #408558;
  color: #fff;
}

.button-inactive-inventory {
  background-color: #fff;
  color: #ddd;
}

.invisble-input {
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  border-radius: 0;
  font-weight: bold;
}

.infoboxes:not(:first-child) {
  margin-left: 20px;
}

.mainbox-options {
  padding: 10px;
  text-align: center;
  border-top: 1px solid #c5c5c4;
  border-right: 1px solid #c5c5c4;
}

.mainbox-options:first-child {
  border-left: 1px solid #c5c5c4;
}
.ib-title {
  font-size: 15px;
  font-weight: 500;
  color: #a9a9a9;
}

.ib-data-1 {
  margin-top: 10px;
  font-weight: bold;
  font-size: 28px;
}

body {
  background-color: #f7f9fd;
}
.newGoals_div_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.dashDivs {
  background-color: #fff;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.newGoals_div:not(:last-child) {
  margin-right: 20px;
  text-align: center;
}

.card-all-data {
  margin-bottom: 20px;
}

.rejected-count {
  text-align: right;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: right;
  color: #28a745;
}
.ts-buttons {
  border-radius: 0;
  margin: 0 10px 0 10px;
  min-width: 75px;
}
.ts-buttons-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.weekly-progress {
  padding: 2px 5px 2px 7px;
  border-radius: 3px;
  font-size: 13px;
}
.perdaynot {
  font-size: 15px;
  margin-left: 4px;
  font-weight: 500;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.progress {
  border-radius: 0;
}

.flex-responsive {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin: 30px 0 10px 0;
}

@media screen and (max-width: 500px) {
  .flex-responsive {
    display: block;
  }
  .flex-responsive > div {
    width: 100% !important;
    border: none !important;
    padding: 0 !important;
    margin-top: 10px !important;
  }
}

input {
  outline: none !important;
  outline-width: 0 !important;
  outline-style: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
  outline-width: 0 !important;
  outline-style: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

*:focus {
  outline: none;
  outline-style: none;
}

.leaderboard {
  margin-top: 25px;
  border-top: 1px solid #ddd;
  padding-top: 15px;
}

.moduleProgressBar {
  width: 160px;
  height: 160px;
}

input[type='checkbox'] {
  width: 20px;
  height: 20px;
}

#filter-settings {
  /* background: #cccaca; */
  background: #fb8037;
  color: white;
  width: 100%;
  position: absolute;
  z-index: 99;
  overflow-y: auto;
  /* padding: 20px; */
}

.reprice-button:disabled {
  background-color: #9ea4aa;
  border: none;
}

.moduleProgressBar:not(:last-child) {
  margin-right: 15px;
}

.tabTitle {
  text-align: center;
  width: 175px;
}

.table-bordered td,
.table-bordered th {
  border: none;
}

.table td,
.table th {
  padding: 0.5rem !important;
  font-size: 12.5px;
  font-weight: 500;
}

.table th {
  font-weight: bold;
}

/* .page-dashboard .table td {
  font-family: sans-serif;
  font-size: 11px !important;
  text-align: center;
} */

/* .page-dashboard .table th {
  font-size: 10px !important;
} */
/* #usersTable th {
  font-size: 14px !important;
} */

.tabTitleLabel,
.tabTitleLabel:hover {
  color: #007bff !important;
  font-size: 20px;
}
.tabTitleLabel:hover {
  color: green;
}
.disabledTitle,
.disabledTitle:hover {
  color: red !important;
  font-size: 20px;
}

.spanClickable {
  color: #007bff !important;
  cursor: pointer;
  margin-right: 15px;
}

.spanClickable:hover {
  text-decoration: underline;
}

.table_triggers {
  font-size: 14px;
}

th,
td {
  padding: 5px !important;
}

.table-responsive {
  overflow-x: visible;
  max-width: 100%;
  /* font-size: 13px; */
}

.dx-g-bs4-table {
  min-width: 0 !important;
  max-width: 100%;
}

.table td,
.table th {
  vertical-align: middle !important;
}

.actionButtonLessPadding {
  padding: 0.175rem 0.65rem !important;
}

.editModal {
  width: 750px;
  max-width: 750px;
}

.input-text-trigger-name,
.input-text-sound-name {
  border: none;
  font-size: 24px;
  border-radius: 0;
}

.input-text-trigger-name:focus,
.input-text-sound-name:focus {
  outline-width: 0;
  outline: none;
  box-shadow: none;
}

.form-control:focus {
  outline-width: 0;
  outline: none;
  box-shadow: none;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: '';
}

.tabletoolkit {
  margin-top: 20px;
}

/* .table td,
.table th {
  font-size: 13px;
} */

.react-bootstrap-table {
  overflow-x: auto !important;
}

/* .dash_table_options_float_right {
  float: right;
} */
.dash_table_options_float_left {
  float: left;
}

.search-bar {
  text-align: left;
}

.dash_table_inputs {
  width: 100%;
  margin: 0 auto;
}

.div-scan-count {
  text-align: center;
  border: 1px solid #ccc;
  margin: 10px 0 10px 0;
  min-width: 175px;
  padding: 10px 5px 10px 5px;
  display: inline-block;
}

/* .div-scan-count:not(:last-child) {
  margin-right: 20px;
} */

.div-scan-count > .scan-count {
  font-size: 32px;
  border-top: 1px solid #38383d;
  margin-top: 20px;
}

.div-scan-count > .reject-count {
  font-size: 14px;
  color: #f20050;
  border-top: 1px solid #dddddd;
  margin-top: 5px;
  padding-top: 8px;
}

.div-scan-count-overall {
  text-align: center;
  border: 1px solid #ccc;
  margin: 4px;
  padding: 10px 5px 10px 5px;
}

.div-scan-count-overall > .scan-count-overall {
  font-size: 32px;
  border-top: 1px solid #38383d;
  margin-top: 20px;
}

.div-scan-count-overall > .reject-count-overall {
  font-size: 14px;
  color: #0444bf;
  border-top: 1px solid #dddddd;
  margin-top: 5px;
  padding-top: 8px;
}

@media only screen and (max-width: 1024px) {
  .dash_table_options_float_right,
  .dash_table_options_float_left {
    float: none;
    text-align: center;
  }

  .search-bar {
    text-align: center;
  }

  .dash_table_inputs {
    width: 100%;
    text-align: center;
  }

  .div-searchbar label {
    width: 100%;
  }
}

.date-range-calendar:not(:focus-within) {
  height: 60px;
  overflow: hidden;
  position: absolute;
  top: 0;
}
